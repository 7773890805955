export enum WEBSOCKET_CLOSE_STATUSES {
  UNACCEPTABLE = 1003,
}

export enum WEB_SOCKET_EVENTS {
  ASK = 'ask',
  BID_SUBMIT = 'bidSubmit',
  LOT = 'lot',
  NEXT_UNSOLD = 'nextUnsold',
  SELL = 'sell',
  UNDO_BID = 'undoBid',
  UNDO_SELL = 'undoSell',
  CLOSE = 'close',
  INTERNET_PUSH_BID = 'bidInternet',
}

export enum CURRENT_WINNER {
  ONLINE = 'internet',
  FLOOR = 'floor',
}

export enum AUCTION_STATES {
  CLOSED = 'Closed',
}

export enum EVENT_DATA_ITEM_STATES {
  SOLD = 'Sold',
}

export enum LOT_ITEM_MESSAGE_TOKENS {
  PASSED = 'lot.message.auction.lot.nextUnsold.passed',
}

export type TBrokerDataItem = {
  itemId: string;
  itemState: EVENT_DATA_ITEM_STATES;
  askPrice: string;
  currency: string;
  currentBid: string;
  currentWinner: string;
  lotNumber: string;
  paddle: string;
  submittedBid: string;
  submittedBidPaddle: string;
  messageToken?: string;
};

export type TBrokerMessageData = {
  auctionId: string;
  auctionState: AUCTION_STATES;
  message: string;
  timestamp: string;
  items: TBrokerDataItem[];
};

export type TBrokerMessage = {
  event: string;
  data: TBrokerMessageData;
};
